import React from 'react';

import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './style.scss';
import { decimalSeparator } from '../../../helpers/decimalSeparator';

export const PieDonut = ({ data, flow = '' }) => {
  const options = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    chart: {
      type: 'pie',
      width: 320,
      height: 320
    },
    title: {
      text: null
    },
    tooltip: {
      outside: true,
      formatter: function () {
        const formattedValue = Highcharts.numberFormat(this.point.y, 1, decimalSeparator(), ' ');
        return `${this.point.name}: ${formattedValue}${flow ? '%' : ' kr'}`;
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: [{
          enabled: false,
        }, {
          enabled: true,
          distance: -40,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '1.2em',
            textOutline: 'none',
            opacity: 0.7
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 10
          }
        }]
      },
      pie: {
        size: 244
      }
    },
    series: [
      {
        name: '',
        data: data?.category,
      },
    ],
  };

  return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
  );
};

PieDonut.propTypes = {
  data: PropTypes.object,
  flow: PropTypes.string
};
