/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { DotChartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LineBasic } from '../../../components/charts/LineBasic/LineBasic';
import { Button, Col, Modal, Row, Skeleton } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import {
  clearPortfolioProspecting,
  createPortfolioTaskAnalysis,
  createPortfolioTaskProspecting,
} from '../../../data/store/portfolio/portfolioActions';

import Container from '../../../components/Container';
import { ReactComponent as Info } from '../../../assets/info-icon.svg';
import { ReactComponent as DotOrange } from '../../../assets/dot-orang.svg';
import { ReactComponent as DotBlue } from '../../../assets/dot-blue.svg';

import { AreaRange } from '../../../components/charts/AreaRange/AreaRange';
import { BarColumnBasic } from '../../../components/charts/BarColumnBasic/BarColumnBasic';
import { BarRace } from '../../../components/charts/BarRace/BarRace';
import { PieDonut } from '../../../components/charts/PieDonut/PieDonut';
import { useBarData } from '../../../hooks/useBarData';
import { colorsBlack } from '../../../helpers/colors';

import { getPieDataOpt } from '../../../hooks/getPieDataOpt';
import MainFooter from '../../../components/MainFooter';
import { oneDecimalWithSpaces } from '../../../helpers/formatFractional';

import './styles.scss';

export const Optimization = ({
  userId,
  portfolioId,
  settings,
  setPDFSource,
  optimalPortfolio,
  setOptimalPortfolio,
  optAlert,
  setOptAlert,
  setOptStart,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const history = useHistory();
  const {
    isLoading,
    analyzedPortfolio,
    analyzedPortfolioDot,
    prospectPortfolioSet,
  } = useSelector((state) => state.portfolioReducer);
  const currentPortfolio = useSelector(
    (state) => state.portfolioReducer.portfolioById,
  );
  const cashesList = useSelector(
    (state) => state.instrumentsReducer?.cashes?.list,
  );
  const { currencyList } = useSelector((state) => state.instrumentsReducer);

  const [portfolioContent, setPortfolioContent] = useState([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  useEffect(() => {
    setOptStart(true);
    if (!settings) {
      history.push(
        `/clients/${userId}/optimization/${portfolioId}/opt-settings`,
      );
    }
    const storName = sessionStorage.getItem('selectedPortfolio');
    if (storName && settings && analyzedPortfolio && prospectPortfolioSet) {
      setOptimalPortfolio(JSON.parse(storName));
    } else {
      sessionStorage.removeItem('selectedPortfolio');
    }
    return () => {
      setOptimalPortfolio(null);
      // dispatch(clearPortfolioProspecting());
    };
  }, []);

  useEffect(() => {
    const portfolioContent = [];
    const getCashData = (id) => {
      const ccc = cashesList?.find((i) => i.ID === id);
      return ccc;
    };
    const getCashRate = (id) => {
      return currencyList?.find((i) => i.id === id)?.rate;
    };
    const portfolio = { ...currentPortfolio };
    const listed =
      portfolio.portfolioContent?.map((i) => {
        return { ...i, portfolio_code: portfolio.portfolio_code };
      }) || [];
    const unListed =
      portfolio.unlistedContent?.map((i) => {
        return {
          ...i,
          ISIN: '- - - -',
          portfolio_code: portfolio.portfolio_code,
        };
      }) || [];
    // eslint-disable-next-line no-unused-vars
    const cashes =
      portfolio.portfolioCashes?.map((i) => {
        return {
          ...i,
          instrumentName: getCashData(i.instrument_id)?.name,
          ISIN: '- - - -',
          unitsNumber: 1,
          latest_value_sek: i.amount * getCashRate(i.currency_id),
          latest_value_time: Date.now(),
          value: i.amount * getCashRate(i.currency_id),
          fee: 0,
          financialAsset: getCashData(i.instrument_id)?.financialAsset,
          financialAssetCategory: getCashData(i.instrument_id)?.financialAsset
            ?.financialAssetCategory,
          portfolio_code: portfolio.portfolio_code,
        };
      }) || [];
    // portfolioContent.push(...listed, ...unListed, ...cashes);
    portfolioContent.push(...listed, ...unListed);
    setPortfolioContent(portfolioContent);
  }, [portfolioId]);

  useEffect(() => {
    setOptimalPortfolio(null);
    if (settings && Object.keys(settings)?.length) {
      const data = {
        ...settings,
        include_financial_plan: !!+settings?.include_financial_plan,
        monetary_basis: +settings?.monetary_basis,
      };
      setTimeout(() => {
        !isLoading &&
          dispatch(createPortfolioTaskProspecting({ portfolioId, data }));
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (optAlert?.flag) setModalCancelVisible(true);
  }, [optAlert]);

  const choosePortfolio = (data) => {
    sessionStorage.setItem(
      'selectedPortfolio',
      JSON.stringify(data.target.options),
    );
    setOptimalPortfolio(data.target.options);
  };
  useEffect(() => {
    if (
      optimalPortfolio &&
      settings &&
      analyzedPortfolio &&
      prospectPortfolioSet
    ) {
      dispatch(
        createPortfolioTaskAnalysis({
          portfolioId,
          include_financial_plan: !!+settings?.include_financial_plan,
          name: optimalPortfolio.name,
          fees: {
            ...settings.fee_flags,
            withdrawal_amount: settings.withdrawal_amount,
            deposit_amount: settings.deposit_amount,
            reserved_amount: settings.reserved_amount,
          },
        }),
      );
    }
  }, [optimalPortfolio]);

  const optimizationDataItem = (text, value) => (
    <div className="optim-top-info-item">
      <span className="optim-top-info-item-title">{text?.toUpperCase()}</span>
      <span
        className={`optim-top-info-item-value ${value > 0 ? 'green' : value < 0 ? 'red' : 'blue'}`}
      >
        {value > 0 ? '+' : ''}
        {value}%
      </span>
    </div>
  );

  const getProspectPortfolioSet = useMemo(() => {
    return prospectPortfolioSet?.prospect_portfolios
      ?.map((i) => {
        return { name: i.id, y: i.mean_return, x: i.volatility, cvar: i.cvar };
      })
      .sort((a, b) => a.x - b.x);
  }, [prospectPortfolioSet]);

  const portfolioTotalValue = useMemo(
    () => currentPortfolio?.total_value,
    [currentPortfolio],
  );

  const barDataCurrent = useBarData(
    portfolioContent,
    colorsBlack,
    portfolioTotalValue,
  );

  const donutPieDataCurrent = useMemo(() => {
    const data = getPieDataOpt(portfolioContent, cashesList);
    return data;
  }, [currentPortfolio, cashesList, portfolioContent]);

  const portfolioTotalValueOpt = useMemo(() => {
    if (prospectPortfolioSet && optimalPortfolio?.name) {
      return prospectPortfolioSet?.prospect_portfolios
        ?.find((i) => i.id === optimalPortfolio?.name)
        ?.items.reduce((acc, el) => acc + el.value, 0);
    } else {
      return 0;
    }
  }, [prospectPortfolioSet, optimalPortfolio]);

  const barDataOpt = useBarData(
    prospectPortfolioSet?.prospect_portfolios?.find(
      (i) => i.id === optimalPortfolio?.name,
    )?.items,
    colorsBlack,
    portfolioTotalValueOpt,
  );

  const donutPieDataOpt = useMemo(() => {
    if (optimalPortfolio?.name) {
      return getPieDataOpt(
        prospectPortfolioSet?.prospect_portfolios?.find(
          (i) => i.id === optimalPortfolio?.name,
        )?.items,
        cashesList,
      );
    }
  }, [prospectPortfolioSet, cashesList, optimalPortfolio]);

  const getChartData = (type) => {
    if (type === 'data1') {
      return (
        analyzedPortfolio?.projection?.map(
          (i) => [i?.year, i?.value_sek] ?? null,
        ) ?? []
      );
    }
    if (type === 'data2') {
      return (
        analyzedPortfolioDot?.projection?.map((i) => [i?.year, i?.value_sek]) ??
        []
      );
    }
    if (type === 'data3') {
      return analyzedPortfolio?.risk?.map((i) => [i?.year, i?.value_sek]) ?? [];
    }
    if (type === 'data4') {
      return (
        analyzedPortfolioDot?.risk?.map((i) => [i?.year, i?.value_sek]) ?? []
      );
    }
    if (type === 'data5') {
      return (
        analyzedPortfolio?.wealth_appreciation?.map((i) => [
          i?.year,
          i?.value,
          {
            return_amount: i?.return_amount,
            deposit_fee_amount: i?.deposit_fee_amount,
            product_fee_amount: i?.product_fee_amount,
            advisory_fee_amount: i?.advisory_fee_amount,
            deposit_amount: i?.deposit_amount,
            withdrawal_amount: i?.withdrawal_amount,
            tax_amount: i?.tax_amount,
          },
        ]) ?? []
      );
    }
    if (type === 'data6') {
      return (
        analyzedPortfolioDot?.wealth_appreciation?.map((i) => [
          i?.year,
          i?.value,
          {
            return_amount: i?.return_amount,
            deposit_fee_amount: i?.deposit_fee_amount,
            product_fee_amount: i?.product_fee_amount,
            advisory_fee_amount: i?.advisory_fee_amount,
            deposit_amount: i?.deposit_amount,
            withdrawal_amount: i?.withdrawal_amount,
            tax_amount: i?.tax_amount,
          },
        ]) ?? []
      );
    }
  };

  const captureForPDF = () => {
    const container1 = document
      .getElementById('pdf-part-1')
      ?.innerHTML.replaceAll('"', '\'');
    const partOneOne = `<div class='opt-inner-wrapper' id='pdf-part-1'>${container1}</div>`;
    const container11 = document
      .getElementById('pdf-part-1-1')
      ?.innerHTML.replaceAll('"', '\'');
    const partOneTwo = `<div class='opt-data-wrap' id='pdf-part-1-1'>${container11}</div>`;
    const container2 = document
      .getElementById('pdf-part-2')
      ?.innerHTML.replaceAll('"', '\'');
    const partTwo = `<div class='opt-charts-wrapper' id='pdf-part-2'>${container2}</div>`;
    const container3 =
      document.getElementById('pdf-part-3')?.innerHTML.replaceAll('"', '\'') ||
      '';
    const partThree = container3
      ? `<div class='opt-charts-wrapper' id='pdf-part-3'>${container3}</div>`
      : '';
    const container4 = document
      .getElementById('pdf-part-4')
      ?.innerHTML.replaceAll('"', '\'');
    const partFour = `<div class='opt-charts-wrapper' id='pdf-part-4'>${container4}</div>`;
    const container5 = document
      .getElementById('pdf-part-5')
      ?.innerHTML.replaceAll('"', '\'');
    const partFive = `<div class='opt-charts-wrapper' id='pdf-part-5'>${container5}</div>`;

    return {
      partOneOne,
      partOneTwo,
      partTwo,
      partThree,
      partFour,
      partFive,
    };
  };

  const handleBack = () => {
    setOptStart(false);
    sessionStorage.removeItem('selectedPortfolio');
    dispatch(clearPortfolioProspecting());
    setPDFSource([]);
    history.goBack();
  };

  const handleNext = () => {
    const template = captureForPDF();
    setPDFSource({
      page1_graph: template?.partOneOne,
      page1_cards: template?.partOneTwo,
      page2_graph: template?.partTwo,
      page3: template?.partThree,
      page4_line_graph_allocation: template?.partFour,
      page4_round_graph_destribution: template?.partFive,
    });
    setTimeout(() => {
      history.push(
        `/clients/${userId}/optimization/${portfolioId}/transactions`,
      );
    }, 500);
  };

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = optAlert.path;
    const state = optAlert.state;
    setOptAlert({ flag: false, path: '' });
    setOptStart(false);
    closeModal();
    history.push(path, state);
  };

  const getDelta = useMemo(() => {
    if (optimalPortfolio?.name && analyzedPortfolio) {
      const optReturn = optimalPortfolio.y;
      const optVolatility = optimalPortfolio.x;
      const optCvar = optimalPortfolio.cvar;

      const currentReturn = analyzedPortfolio.mean_return;
      const currentVolatility = analyzedPortfolio.volatility;
      const currentCvar = analyzedPortfolio.cvar;

      const getData = (value) => {
        const color = value > 0 ? 'green' : value < 0 ? 'red' : 'blue';
        const prefix = value > 0 ? '+' : '';
        return { color, prefix };
      };

      const deltaReturn = Math.round((optReturn - currentReturn) * 10) / 10;
      const deltaVolatility =
        Math.round((optVolatility - currentVolatility) * 10) / 10;
      const deltaCvar = Math.round((optCvar - currentCvar) * 10) / 10;
      const deltaObj = {
        deltaReturn: { value: deltaReturn, ...getData(deltaReturn) },
        deltaVolatility: {
          value: deltaVolatility,
          ...getData(deltaVolatility),
        },
        deltaCvar: { value: deltaCvar, ...getData(deltaCvar) },
      };
      return deltaObj;
    }
  }, [optimalPortfolio, analyzedPortfolio]);

  const handleReset = () => {
    setOptAlert({ flag: false, path: '' });
    setOptStart(false);
    history.push(`/clients/${userId}/portfolio`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100vh - 80px)',
      }}
    >
      <div className="top-btn-wrapper">
        <Button
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          className="portfolio-details-btn optim-change-btn"
          onClick={handleBack}
        >
          {t('BACK_TO_SETTINGS')}
        </Button>
        <Button
          disabled={!optimalPortfolio?.name || isLoading}
          onClick={handleNext}
          className="btn-edit-save optim-continue-btn"
        >
          {t('TRANSACTIONS')}
        </Button>
      </div>
      {optimalPortfolio?.name && (
        <div className="optimisation-chart-container-data-items">
          {optimizationDataItem(
            t('RETURN'),
            `${optimalPortfolio?.y?.toFixed(1) ?? ''}`,
          )}
          {optimizationDataItem(
            t('VOLATILITY'),
            `${optimalPortfolio?.x?.toFixed(1) ?? ''}`,
          )}
          {optimizationDataItem(
            'CVaR',
            `${optimalPortfolio?.cvar?.toFixed(1) ?? ''}`,
          )}
        </div>
      )}
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ padding: '36px 40px' }}
      >
        <Col style={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}>
          <h2 style={{ margin: '0' }} className="portfolio-header-title">
            {t('PORTFOLIO')}
            <span className="orang-dots">:</span>
            {t('OPTIMIZATION')}
          </h2>
          <span className="opt-user-name">{`${currentPortfolio?.name}`}</span>
          <span
            style={{
            fontSize: 14,
            marginleft: 33,
            fontWeight: 400,
          }}>{`external Quant ID: ${currentPortfolio?.externalID || ''}`}</span>        </Col>
        <Col style={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
          {!optimalPortfolio && (
            <>
              <Info /> {t('INFO_TEXT')}
            </>
          )}
        </Col>
      </Row>
      <div className="opt-inner-wrapper">
        <div className="opt-chart-wrap" id="pdf-part-1">
          <Container widthP={100} mb="0" padding="32px 28px">
            <Row className="opt-title-wrap">
              <Col>
                <h2 className="portfolio-container-title">
                  {t('EFFICIENT_FRONTIER')}
                </h2>
              </Col>
            </Row>
            <div className="optimisation-chart-container">
              {isLoading && !prospectPortfolioSet?.prospect_portfolios ? (
                <Skeleton.Node
                  active={true}
                  style={{ width: '100%', minHeight: '400px' }}
                >
                  <DotChartOutlined
                    style={{ fontSize: 400, color: '#bfbfbf' }}
                  />
                </Skeleton.Node>
              ) : (
                <LineBasic
                  data={getProspectPortfolioSet}
                  currentPortfolioData={[
                    {
                      key: 'current',
                      y: Math.round(analyzedPortfolio?.mean_return * 10) / 10,
                      x: Math.round(analyzedPortfolio?.volatility * 10) / 10,
                      cvar: Math.round(analyzedPortfolio?.cvar * 10) / 10,
                    },
                  ]}
                  selectPoint={(data) => choosePortfolio(data)}
                  point={optimalPortfolio?.name}
                />
              )}
            </div>
          </Container>
        </div>
        <div className="opt-data-wrap" id="pdf-part-1-1">
          <Container mb="0" padding="0" style={{ width: '100%' }}>
            <div className="optimisation-table-wrapper">
              <div className="optimisation-optdata">
                <p className="optimisation-optdata-text">
                  {t('OPTIMIZED_PORTFOLIO')}
                </p>
                <div className="optimisation-optdata-card">
                  <p className="optimisation-optdata-card-title">
                    <DotOrange />
                    {t('RETURN').toUpperCase()}
                  </p>
                  <div className="optimisation-optdata-card-data-wrap">
                    <p className="optimisation-optdata-card-data">
                      {optimalPortfolio?.y
                        ? `${optimalPortfolio?.y?.toFixed(1)}%`
                        : '0.0%'}
                    </p>
                    <p
                      className={`optimisation-optdata-card-dig ${getDelta?.deltaReturn?.color}`}
                    >
                      {getDelta?.deltaReturn?.prefix}
                      {getDelta?.deltaReturn?.value ?? 0}%
                    </p>
                  </div>
                </div>
                <div className="optimisation-optdata-card">
                  <p className="optimisation-optdata-card-title">
                    <DotOrange />
                    {t('VOLATILITY').toUpperCase()}
                  </p>
                  <div className="optimisation-optdata-card-data-wrap">
                    <p className="optimisation-optdata-card-data">
                      {optimalPortfolio?.x
                        ? `${optimalPortfolio?.x?.toFixed(1)}%`
                        : '0.0%'}
                    </p>
                    <p
                      className={`optimisation-optdata-card-dig ${getDelta?.deltaVolatility?.color}`}
                    >
                      {getDelta?.deltaVolatility?.prefix}
                      {getDelta?.deltaVolatility?.value ?? 0}%
                    </p>
                  </div>
                </div>
                <div className="optimisation-optdata-card">
                  <p className="optimisation-optdata-card-title">
                    <DotOrange />
                    CVaR
                  </p>
                  <div className="optimisation-optdata-card-data-wrap">
                    <p className="optimisation-optdata-card-data">
                      {optimalPortfolio?.cvar
                        ? `${optimalPortfolio?.cvar?.toFixed(1)}%`
                        : '0.0%'}
                    </p>
                    <p
                      className={`optimisation-optdata-card-dig ${getDelta?.deltaCvar?.color}`}
                    >
                      {getDelta?.deltaCvar?.prefix}
                      {getDelta?.deltaCvar?.value ?? 0}%
                    </p>
                  </div>
                </div>
              </div>

              <div className="optimisation-currentdata">
                <p className="optimisation-optdata-text">
                  {t('CURRENT_PORTFOLIO')}
                </p>
                <div className="optimisation-optdata-card">
                  <p className="optimisation-optdata-card-title">
                    <DotBlue />
                    {t('RETURN').toUpperCase()}
                  </p>
                  <div className="optimisation-optdata-card-data-wrap">
                    <p className="optimisation-optdata-card-data">
                      {currentPortfolio?.return
                        ? `${currentPortfolio?.return?.toFixed(1)}%`
                        : '0.0%'}
                    </p>
                  </div>
                </div>
                <div className="optimisation-optdata-card">
                  <p className="optimisation-optdata-card-title">
                    <DotBlue />
                    {t('VOLATILITY').toUpperCase()}
                  </p>
                  <div className="optimisation-optdata-card-data-wrap">
                    <p className="optimisation-optdata-card-data">
                      {currentPortfolio?.vol
                        ? `${currentPortfolio?.vol?.toFixed(1)}%`
                        : '0.0%'}
                    </p>
                  </div>
                </div>
                <div className="optimisation-optdata-card">
                  <p className="optimisation-optdata-card-title">
                    <DotBlue />
                    CVaR
                  </p>
                  <div className="optimisation-optdata-card-data-wrap">
                    <p className="optimisation-optdata-card-data">
                      {currentPortfolio?.cvar
                        ? `${currentPortfolio?.cvar?.toFixed(1)}%`
                        : '0.0%'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div>
        {optimalPortfolio && (
          <>
            <div className="opt-charts-wrapper" id="pdf-part-2">
              <Container widthP={100} mb="0" padding="31px 40px">
                <Row className="opt-title-wrap">
                  <Col>
                    <h2 className="opt-paper-title">
                      {t('PORTFOLIO_DEVELOPMENT')}
                    </h2>
                  </Col>
                </Row>
                <div style={{ width: '100%' }}>
                  {isLoading ? (
                    <Skeleton.Node
                      active={true}
                      style={{ width: '100%', minHeight: '400px' }}
                    >
                      <DotChartOutlined
                        style={{ fontSize: 400, color: '#bfbfbf' }}
                      />
                    </Skeleton.Node>
                  ) : (
                    <AreaRange
                      data1={getChartData('data1')}
                      data2={getChartData('data2')}
                      data3={getChartData('data3')}
                      data4={getChartData('data4')}
                    />
                  )}
                </div>
              </Container>
            </div>
            {settings.include_financial_plan === '1' && (
              <div className="opt-charts-wrapper" id="pdf-part-3">
                <Container widthP={100} mb="0" padding="31px 40px">
                  <Row className="opt-title-wrap">
                    <Col>
                      <h2 className="opt-paper-title">
                        {t('WEALTH_APPRECIATION')}
                      </h2>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Skeleton.Node
                      active={true}
                      style={{ width: '100%', minHeight: '400px' }}
                    >
                      <DotChartOutlined
                        style={{ fontSize: 400, color: '#bfbfbf' }}
                      />
                    </Skeleton.Node>
                  ) : (
                    <BarColumnBasic
                      data1={getChartData('data5')}
                      data2={getChartData('data6')}
                    />
                  )}
                </Container>
              </div>
            )}
            <div className="opt-charts-wrapper" id="pdf-part-4">
              <Container widthP={100} mb="0" padding="31px 40px">
                <Row className="opt-title-wrap">
                  <Col>
                    <h2 className="opt-paper-title">{t('ASSET_ALLOCATION')}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="asset-allocation-title ">
                    {t('CURRENT_PORTFOLIO')}
                  </Col>
                  <Col span={12} className="asset-allocation-title ">
                    {t('OPTIMIZED_PORTFOLIO')}
                  </Col>
                </Row>
                <Row className="current-portfolio-graphics-row">
                  <Col span={12}>
                    <BarRace data={barDataCurrent() || []} />
                  </Col>
                  <Col span={12}>
                    <BarRace data={barDataOpt() || []} />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="opt-charts-wrapper" id="pdf-part-5">
              <Container widthP={100} mb="0" padding="31px 40px 32px">
                <Row className="opt-title-wrap">
                  <Col>
                    <h2 className="opt-paper-title">{t('DISTRIBUTION')}</h2>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '40px' }}>
                  <Col span={12} className="asset-allocation-title ">
                    {t('CURRENT_PORTFOLIO')}
                  </Col>
                  <Col span={12} className="asset-allocation-title ">
                    {t('OPTIMIZED_PORTFOLIO')}
                  </Col>
                </Row>
                <Row className="current-portfolio-graphics-row">
                  <Col span={12} className="donut-wrap">
                    <div className="donut-data-chart">
                      <div className="donut-chart-wrapper">
                        <PieDonut data={donutPieDataCurrent ?? {}} flow="opt" />
                      </div>
                    </div>
                    <div className="donut-data">
                      {donutPieDataCurrent?.category?.map((i) => {
                        return (
                          <div key={i.name} className="donut-data-row">
                            <div className="donut-category-wrap">
                              <div
                                className="donut-category-legend"
                                style={{ background: i.color }}
                              />
                              <p className="donut-category-name">{i.name}</p>
                            </div>
                            <p className="donut-category-persent">
                              {oneDecimalWithSpaces(i.y)}%
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col span={12} className="donut-wrap">
                    <div className="donut-data-chart">
                      <div className="donut-chart-wrapper">
                        <PieDonut data={donutPieDataOpt ?? {}} flow="opt" />
                      </div>
                    </div>
                    <div className="donut-data">
                      {donutPieDataOpt?.category?.map((i) => {
                        return (
                          <div key={i.name} className="donut-data-row">
                            <div className="donut-category-wrap">
                              <div
                                className="donut-category-legend"
                                style={{ background: i.color }}
                              />
                              <p className="donut-category-name">{i.name}</p>
                            </div>
                            <p className="donut-category-persent">
                              {oneDecimalWithSpaces(i.y)}%
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      </div>
      <Modal
        width={650}
        className="modal-content-paper"
        open={modalCancelVisible}
        onCancel={closeModal}
        footer={null}
        zIndex={1620}
      >
        <Row justify="center">
          <Col className="modal-title">{t('WARNING_OPT')}</Col>
        </Row>
        <Row justify="center" className="modal-text-wrapper">
          <Col span={18}>
            <p className="modal-text">{t('WARNING_OPT_TEXT')}</p>
          </Col>
        </Row>

        <Row className="modal-button-wrapper">
          <Button
            className="modal-action-btn cancel-btn"
            onClick={cancelWithoutChange}
          >
            {t('LEAVE_OPTIMIZATION_FLOW')}
          </Button>
          <Button className="modal-action-btn save-btn" onClick={closeModal}>
            {t('CONTINUE_OPTIMIZATION')}
          </Button>
        </Row>
      </Modal>
      <MainFooter />
    </div>
  );
};

Optimization.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  setPDFSource: PropTypes.func,
  settings: PropTypes.object,
  optimalPortfolio: PropTypes.object,
  setOptimalPortfolio: PropTypes.func,
  optAlert: PropTypes.object,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
