import React from 'react';
import { Col, Row, Layout } from 'antd';
import StorageService from '../../services/StorageService';
import { useTranslation } from 'react-i18next';

import SessionService from '../../services/SessionService';
import { ReactComponent as Phone } from './img/phone-icon.svg';
import { ReactComponent as Map } from './img/map-icon.svg';
import { ReactComponent as Arrow } from '../../assets/arrow-up-right.svg';

import './style.scss';

const { Footer } = Layout;

const MainFooter = () => {
  const { t } = useTranslation('');
  const token =
    StorageService.getJWTToken('acc') || SessionService.getJWTToken('acc');

  return (
    <Footer className="footer">
      {!token && <div className="vertical-line" />}

      <Row className="footer-row">
        <Col className="footer-col">
          <span>Citroneer AB</span>
          <p><Phone /><span>+46 (0)8 760 99 70</span></p>
          <p><Map /><span>Artillerigatan 42, 114 45 Stockholm</span></p>
        </Col>

        <Col className="footer-col center">
            <a href="https://citroneer.com/">
              {t('BACK_TO_CITRONEERS')}
            </a>
            <Arrow />
        </Col>
      </Row>
    </Footer>
  );
};

export default MainFooter;
