import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  Redirect
} from 'react-router-dom';

import Report from '../Report';
import { useDispatch, useSelector } from 'react-redux';
import { clearPortfolioList, getAllPortfolio } from '../../data/store/portfolio/portfolioActions';
import { getClient, getFinPlan } from '../../data/store/clients/clientActions';
import FinancialPlanning from '../FinancialPlanning/FinancialPlanning';
import OrderInstructions from '../OrderInstructions/OrderInstructions';
import Monitoring from '../Monitoring/Monitoring';
import { StepRouter } from '../PortfolioOptimization/StepRouter';
import PortfolioRoute from '../Portfolio/PortfolioRoute';
import ClientSummary from '../Clients/ClientSummary';
import NewPortfolio from '../Clients/NewPortfolio';
import ProfileRouting from './ProfileRouting';
import FinancialPlanningSummary from '../FinancialPlanning/FinancialPlanningSummary';
import FinancialPlanningItem from '../FinancialPlanning/FinancialPlanningItem';
import { PrivateRouterWithProps } from '../../PrivateRouterWithProps';
import { permissions } from '../../config/permissions';
import SuggestedRout from '../SuggestedRoot/SuggestedRoot';

const ClientRouting = ({
  setUserId, needSave, setNeedSave, saveAlert,
  setSaveAlert, optAlert, setOptAlert, setOptStart
}) => {
  const { url } = useRouteMatch();
  const { userId } = useParams();
  const dispatch = useDispatch();

  const { finPlan } = useSelector((state) => state.clientReducer);

  const { ADVISER, SUPER_ADMIN } = permissions.roles;

  useEffect(() => {
    if (userId) {
      dispatch(clearPortfolioList());
      dispatch(getAllPortfolio(userId));
      dispatch(getClient({ id: userId }));
      setUserId(+userId);
      dispatch(getFinPlan(userId));
    }
  }, [userId]);

  return (
    <>
      <Switch>
        <Route path={`${url}/portfolio`} exact>
          <ClientSummary
            userId={userId}
          />
        </Route>
        <Route path={`${url}/new-portfolio`} exact>
          <NewPortfolio
            userId={userId}
          />
        </Route>
        <Route path={`${url}/portfolio/:portfolioId`}>
          <PortfolioRoute
            userId={userId}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route path={`${url}/optimization/:portfolioId`} >
          <StepRouter
            userId={userId}
            optAlert={optAlert}
            setOptAlert={setOptAlert}
            setOptStart={setOptStart}
          />
        </Route>
        <Route exact path={`${url}/order-instructions`}>
          <OrderInstructions userId={userId}/>
        </Route>
        <Route exact path={`${url}/monitoring`}>
          <Monitoring id={userId}/>
        </Route>
        <Route exact path={`${url}/lifetiming`}>
          <FinancialPlanning userId={userId}/>
        </Route>
        <Route exact path={`${url}/lifetiming/:finplanId/items/:mode`}>
          <FinancialPlanningItem
            userId={userId}
            finPlan={finPlan}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route exact path={`${url}/lifetiming/:finplanId`}>
          <FinancialPlanningSummary
            userId={userId}
            finPlan={finPlan}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route exact path={`${url}/documents`}>
          <Report userId={userId}/>
        </Route>
        <Route exact path={`${url}/suggested-portfolios`}>
          <Redirect to={`${url}/suggested-portfolios/suggestions`}/>
        </Route>
        <PrivateRouterWithProps
          path={`${url}/suggested-portfolios/:typeTab`}
          roles={[ADVISER, SUPER_ADMIN]}
          customProps={{ userId }}
          component={SuggestedRout}
        />
        <Route path={`${url}/inform`}>
          <ProfileRouting
            userId={userId}
            setUserId={setUserId}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
      </Switch>
    </>
  );
};

export default ClientRouting;

ClientRouting.propTypes = {
  userId: PropTypes.string,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  optAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
