/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ArrowUp } from '../../assets/arrow-lefup-green.svg';

import { ReactComponent as PortfolioUnselected } from '../../assets/portfolio-unselected.svg';
import { ReactComponent as PortfolioSelected } from '../../assets/portfolio-selected.svg';
import { fractionalWithSpaces } from '../../helpers/formatFractional';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

const ColorDataItem = ({text, value, isSelected}) => (
  <div className="portfolio-card-item ">
    <span className={`portfolio-card-item-title ${isSelected ? 'selected' : ''}`}>{text}</span>
    <span
      className={`portfolio-card-item-value ${value >= 0 ? 'green' : 'red'}`}
    >
      {value > 0 ? '+' : ''}
      {value}%
    </span>
  </div>
);

const PortfolioListItem = ({ item, selectedRowKeys, setSelectedRowKeys, deprecated, is_locked }) => {
  console.log('🚀 ~ PortfolioListItem ~ item:', item);
  const { t } = useTranslation('');
  const { name, total_value, portfolio_code } = item;
  const return_main = item.return;
  const volatility = item.vol;
  const cvar = item.cvar;

  const isSelecte = useMemo(() => {
    return selectedRowKeys?.includes(item?.ID);
  }, [selectedRowKeys, item]);

  const setItemSelected = () => {
    if (selectedRowKeys?.includes(item?.ID)) {
      setSelectedRowKeys(selectedRowKeys.filter(i => i !== item?.ID));
    } else {
      setSelectedRowKeys([...selectedRowKeys, item?.ID]);
    }
  };

  return (
      <div
        onClick={setItemSelected}
        className={`portfolio-card ${!isSelecte ? 'unselected' : ''}`}>
        <p className='portfolio-card-icons'>
          {isSelecte ? <PortfolioSelected /> : <PortfolioUnselected/>}
        </p>
        <p className='portfolio-card-name'>{name}</p>
        <p className='portfolio-card-code'>
          {portfolio_code}
          {deprecated
            ? <span className='portfolio-card-deprecated'>{t('IN_EXECUTION')}</span>
            : is_locked
              ? <span className='portfolio-card-deprecated'>{t('LOCKED')}</span>
              : null}
        </p>
        <p className='portfolio-card-value'>{`${fractionalWithSpaces(total_value)} SEK`}</p>
        <Row justify={'center'} className='portfolio-card-text'>
          <Col className=''>
            <ColorDataItem
              isSelected={isSelecte} 
              text={t('RETURN')} 
              value={return_main}/>

          </Col>
          <Col className=''>
            <ColorDataItem
              isSelected={isSelecte} 
              text={t('VOLATILITY')} 
              value={volatility}/>

          </Col>
          <Col className=''>
            <ColorDataItem
              isSelected={isSelecte} 
              text={t('CVaR')} 
              value={cvar}/>

          </Col>
        </Row>
      </div>
  );
};

export default PortfolioListItem;

PortfolioListItem.propTypes = {
  item: PropTypes.object,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  deprecated: PropTypes.bool,
  is_locked: PropTypes.bool,
};

ColorDataItem.propTypes = {
  text: PropTypes.string,
  value: PropTypes.number,
  isSelected: PropTypes.bool,
};
